import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FeaturedContentComponent } from './components/featured-content/featured-content.component';
import { FeaturedCourseCardComponent } from './components/featured-course-card/featured-course-card.component';
import { DateFormatModule } from '../../global/date-format/date-format.module';
import { FeaturedContentSvgComponent } from './components/featured-content-svg/featured-content-svg.component';
import { CtaButtonComponent } from '../../../core-ui/components/cta-button/cta-button.component';

import { ClinicCardComponent } from './components/clinic-card/clinic-card.component';
import { FeaturedImageComponent } from './components/featured-image/featured-image.component';
import { FeaturedContentCardComponent } from './components/featured-content-card/featured-content-card.component';
import { CpdModuleCardComponent } from './components/cpd-module-card/cpd-module-card.component';
import { ClinicImageComponent } from './components/clinic-image/clinic-image.component';
import { PlainButtonComponent } from 'src/app/core-ui/components/plain-button/plain-button.component';
import { FeaturedClinicComponent } from './components/featured-content/featured-clinic/featured-clinic.component';
import { HarmfulGamblingComponent } from './components/featured-content/clinic-cards/harmful-gambling/harmful-gambling.component';
import { SleepMattersComponent } from './components/featured-content/clinic-cards/sleep-matters/sleep-matters.component';
import { InclusionHealthComponent } from './components/featured-content/clinic-cards/inclusion-health/inclusion-health.component';

@NgModule({
  declarations: [
    FeaturedContentComponent,
    FeaturedCourseCardComponent,
    FeaturedContentSvgComponent,
    ClinicCardComponent,
    ClinicImageComponent,
    FeaturedImageComponent,
    FeaturedContentCardComponent,
    CpdModuleCardComponent,
    FeaturedClinicComponent,
    HarmfulGamblingComponent,
    SleepMattersComponent,
    InclusionHealthComponent,
  ],
  imports: [
    CommonModule,
    DateFormatModule,
    CtaButtonComponent,
    PlainButtonComponent,
  ],
  exports: [
    FeaturedContentComponent,
    FeaturedCourseCardComponent,
    ClinicCardComponent,
    ClinicImageComponent,
    FeaturedImageComponent,
    FeaturedContentCardComponent,
    CpdModuleCardComponent,
    ClinicCardComponent,
    FeaturedClinicComponent,
  ],
})
export class FeaturedContentModule {}
